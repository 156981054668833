<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div class>
      <a-form-model
        ref="dictionaryItemForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="名称" prop="itemName">
          <a-input placeholder="请输入" v-model="info.itemName"></a-input>
        </a-form-model-item>
        <a-form-model-item label="父级" v-if="dinfo.type == 2">
          {{ selectParent.text }} &nbsp;
          <a-cascader
            placeholder="请选择"
            :options="treeDatas"
            :fieldNames="{
              label: 'itemName',
              value: 'id',
              children: 'children',
            }"
            @change="onTreeChange"
            changeOnSelect
          >
            <pxkeji-btn name="请选择" type="primary" />
          </a-cascader>
        </a-form-model-item>
        <a-form-model-item label="字典项值">
          <a-input placeholder="请输入" v-model="info.itemValue"></a-input>
        </a-form-model-item>
        <a-form-model-item label="排序">
          <a-input placeholder="请输入排序" v-model="info.orderno"></a-input>
        </a-form-model-item>
        <a-form-model-item label="图片">
          <a-upload
            listType="picture-card"
            :action="$api.file.uploadFile"
            :fileList="fileList"
            :beforeUpload="beforeUpload"
            @preview="handlePreview"
            @change="handleChange"
          >
            <div v-if="fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="example" style="width: 100%" :src="info.picture" />
          </a-modal>
        </a-form-model-item>
        <a-form-model-item label="前台显示" v-if="dinfo.type == 4">
          <a-switch
            v-model="info.isShow"
            checked-children="是"
            un-checked-children="否"
          />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
var _this;
export default {
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
    dinfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      info: {
        orderno: 0,
        parentId: 0,
        isShow: true,
      },
      rules: {
        itemName: [],
      },
      fileList: [],
      previewVisible: false,
      treeDatas: [
        {
          id: "0",
          itemName: "根目录",
          children: [],
        },
      ],
      selectParent: {
        id: 0,
        text: "根目录",
      },
    };
  },
  components: {},
  mounted() {
    _this = this;
    if (_this.dinfo.type == 2) {
      _this.GetTree();
    }
    if (_this.infos.id) {
      _this.getInfo();
    }
  },
  methods: {
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    /**获取详情 */
    getInfo() {
      _this.isloading = true;
      _this.$api.dictionaryItem
        .getById(_this.infos.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.info = res.data;
            if (_this.dinfo.type == 2) {
              _this.selectParent.id = res.data.parentId;
              _this.selectParent.text = res.data.parentName;
            }
            if (_this.dinfo.type == 4) {
              _this.info.isShow = _this.$commen.changeIntBoolean(
                res.data.isShow
              );
            }

            if (res.data.picture.length > 0) {
              _this.fileList.push({
                uid: "1",
                name: "图片.png",
                status: "done",
                url: res.data.picture,
              });
            }
            _this.isloading = false;
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    async GetTree() {
      _this.isloading = true;
      let res = await _this.$api.dictionaryItem.tree(_this.dinfo.id);
      if (res.errorCode == _this.$msg.responseCode.success) {
        _this.treeDatas[0].children = res.data;
      } else {
        _this.$message.error(res.errorMsg);
      }
      _this.isloading = false;
    },
    handleSubmit() {
      _this.$refs.dictionaryItemForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          if (_this.infos.id) {
            //修改
            _this.$api.dictionaryItem
              .update(
                _this.infos.id,
                _this.dinfo.id,
                _this.info.itemName,
                _this.info.orderno,
                _this.info.itemValue,
                _this.info.parentId,
                _this.$commen.changeBooleanInt(_this.info.isShow),
                _this.info.picture
              )
              .then((res) => {
                if (res.errorCode == _this.$msg.responseCode.success) {
                  _this.$message.success(res.errorMsg);
                  _this.$emit("callbackMethod");
                } else {
                  _this.$message.error(res.errorMsg);
                }
                _this.isloading = false;
              })
              .catch(() => {
                _this.isloading = false;
              });
          } else {
            //添加
            _this.$api.dictionaryItem
              .save(
                _this.dinfo.id,
                _this.info.itemName,
                _this.info.orderno,
                _this.info.itemValue,
                _this.info.parentId,
                _this.$commen.changeBooleanInt(_this.info.isShow),
                _this.info.picture
              )
              .then((res) => {
                if (res.errorCode == _this.$msg.responseCode.success) {
                  _this.$message.success(res.errorMsg);
                  _this.$emit("callbackMethod");
                } else {
                  _this.$message.error(res.errorMsg);
                }
                _this.isloading = false;
              })
              .catch(() => {
                _this.isloading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    onTreeChange(value, selectedOptions) {
      if (_this.infos.id) {
        if (_this.infos.id == selectedOptions[selectedOptions.length - 1].id) {
          _this.$message.error("不能选择自己作为父级");
          return _this;
        }
      }
      _this.selectParent.text =
        selectedOptions[selectedOptions.length - 1].itemName;
      _this.selectParent.id = selectedOptions[selectedOptions.length - 1].id;
      _this.info.parentId = _this.selectParent.id;
    },
    handleCancel() {
      _this.previewVisible = false;
    },
    handlePreview() {
      _this.previewVisible = true;
    },
    beforeUpload(file) {
      let isJPGPNG = false;
      if (file.type === "image/jpeg" || file.type === "image/png") {
        isJPGPNG = true;
      } else {
        isJPGPNG = false;
        _this.$message.error("只能上传jpg/png格式的文件!");
      }
      let isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        _this.$message.error("文件不能超过2MB!");
      }
      return isJPGPNG && isLt2M;
    },
    handleChange(info) {
      _this.fileList = info.fileList;
      if (info.file.status == "done") {
        if (info.file.response.errorCode == "0000") {
          _this.info.picture = info.file.response.data.path;
        } else {
          _this.$message.error("上传失败");
        }
      } else if (info.file.status == "error") {
        _this.$message.error("上传失败");
      } else if (info.file.status == "removed") {
        _this.fileList = [];
        _this.info.picture = "";
      }
    },
  },
};
</script>
<style>
</style>