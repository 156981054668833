<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="dictionaryForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="字典名称" prop="name">
          <a-input placeholder="请输入" v-model="info.name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="描述">
          <a-input
            placeholder="请输入描述"
            v-model="info.description"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="类型">
          <a-select v-model="info.type" placeholder="请选择" allowClear>
            <a-select-option :value="1">键值对</a-select-option>
            <a-select-option :value="2">树形</a-select-option>
            <a-select-option :value="4">标签</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 19, offset: 5 }">
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
let _this;
export default {
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      info: {
        type: 1,
      },
      rules: {
        name: [{ required: true, message: "请输入字典名称", trigger: "blur" }],
      },
    };
  },
  mounted() {
    _this = this;
    if (_this.infos.id) {
      _this.getInfo();
    }
  },
  methods: {
    getInfo() {
      _this.isloading = true;
      _this.$api.dictionaries
        .getById(_this.infos.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.info = res.data;
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      _this.$refs.dictionaryForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.$api.dictionaries
            .update(
              _this.infos.id,
              _this.info.name,
              _this.info.description,
              _this.info.type
            )
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
</style>