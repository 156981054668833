<template>
  <div>
    <div class="pxkeji-right-box">
      <pxkeji-btn
        name="添加"
        class="pxkeji_basic_btn"
        @click="showInfo({ id: 0 })"
        type="primary"
        :roles="['数据字典-新增子项']"
      />
    </div>
    <div style="margin-top: 8px">
      <a-table
        v-if="infos.type == 2"
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :dataSource="list.datas"
        :pagination="false"
        :loading="isloading"
        :locale="{ emptyText: '暂无数据' }"
      >
        <span slot="action" slot-scope="action">
          <pxkeji-btn
            name="编辑"
            @click="showInfo(action)"
            size="small"
            type="primary"
            :roles="['数据字典-编辑子项']"
          />
          <a-popconfirm
            title="是否确认删除此字典项？"
            @confirm="delInfo(action.id)"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="删除"
              size="small"
              type="danger"
              :roles="['数据字典-删除子项']"
            />
          </a-popconfirm>
        </span>
      </a-table>

      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :locale="{ emptyText: '暂无数据' }"
        v-else
      >
        <span slot="action" slot-scope="action">
          <pxkeji-btn
            name="编辑"
            @click="showInfo(action)"
            size="small"
            type="primary"
            :roles="['数据字典-编辑子项']"
          />
          <a-popconfirm
            title="是否确认删除此字典项？"
            @confirm="delInfo(action.id)"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="删除"
              size="small"
              type="danger"
              :roles="['数据字典-删除子项']"
            />
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <a-modal
      :title="addedit.title"
      v-model="addedit.visible"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <Info @callbackMethod="save" :dinfo="infos" :infos="addedit.info" />
    </a-modal>
  </div>
</template>
<script>
import Info from "./dictionaryItemInfo.vue"; //编辑

const columns = [
  {
    dataIndex: "id",
    key: "id",
    align: "center",
    title: "编号",
  },
  {
    dataIndex: "itemName",
    key: "itemName",
    align: "center",
    title: "名称",
  },

  {
    dataIndex: "itemValue",
    key: "itemValue",
    align: "center",
    title: "字典项值",
  },
  {
    dataIndex: "orderno",
    key: "orderno",
    align: "center",
    title: "排序",
  },
  {
    title: "操作",
    key: "action",
    width: 200,
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

let _this;
export default {
  name: "dictionaryItemList",
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
      },
      isloading: false,
      addedit: {
        title: "",
        info: {},
        id: 0,
        visible: false,
      },
    };
  },
  components: {
    Info,
  },
  mounted: function () {
    _this = this;
    if (_this.infos.type == 2) {
      _this.GetTree();
    } else {
      _this.GetList();
    }
  },
  methods: {
    GetList() {
      _this.isloading = true;
      _this.$api.dictionaryItem
        .pageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.infos.id
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    GetTree() {
      //获取列表
      _this.isloading = true;
      _this.$api.dictionaryItem
        .tree(_this.infos.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data;
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    showInfo(info) {
      _this.addedit.visible = true;
      _this.addedit.title = info.id ? "编辑" : "新增";
      _this.addedit.info = info;
    },
    /**删除 */
    delInfo(id) {
      _this.isloading = true;
      _this.$api.dictionaryItem
        .del(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.isloading = false;
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    //子组件传值
    save() {
      _this.addedit.visible = false;
      if (_this.infos.type == 1) {
        _this.GetList();
      } else {
        _this.GetTree();
      }
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.cang_sta {
  text-align: center;
}

.cang_sta img {
  width: 35px;
}
</style>

