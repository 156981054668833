<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="名称">
              <a-input placeholder="请输入" v-model="search.name" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <a-table
        rowKey="id"
        bordered
        size="small"
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <span slot="action" slot-scope="action">
          <pxkeji-btn
            name="修改"
            @click="showInfo(action)"
            size="small"
            type="primary"
            :roles="['数据字典-编辑字典']"
          />
          <pxkeji-btn
            name="数据"
            @click="showList(action)"
            size="small"
            :roles="['数据字典-管理子项']"
          />
        </span>
      </a-table>
    </div>

    <a-modal
      :title="addedit.title"
      v-model="addedit.visible"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <Info :infos="addedit.info" @callbackMethod="save" />
    </a-modal>

    <!---子集-->
    <a-modal
      :title="itemListInfo.title"
      v-model="itemListInfo.visible"
      :footer="false"
      destroyOnClose
      width="1200px"
      :maskClosable="false"
    >
      <itemList @callbackMethod="save" :infos="itemListInfo.info" />
    </a-modal>
  </div>
</template>
<script>
import Info from "./dictionaryInfo.vue";
import itemList from "./dictionaryItemList.vue";
let _this;
const columns = [
  {
    dataIndex: "id",
    align: "center",
    title: "编号",
  },
  {
    dataIndex: "name",
    align: "center",
    title: "名称",
  },
  {
    dataIndex: "description",
    align: "center",
    title: "描述",
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "dictionarylist",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 252,
      },
      //查询条件
      search: {
        name: "",
      },
      isloading: false,
      addedit: {
        title: "",
        info: {},
        visible: false,
      },
      itemListInfo: {
        title: "",
        info: {},
        visible: false,
      },
    };
  },
  components: {
    itemList,
    Info,
  },
  mounted: function () {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 252;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.GetList();
  },
  methods: {
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.$api.dictionaries
        .pageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.search.name
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    showInfo(info) {
      //打开弹出框
      _this.addedit.visible = true;
      _this.addedit.title = info.id ? "编辑" : "新增";
      _this.addedit.info = info;
    },
    showList(info) {
      //打开弹出框
      _this.itemListInfo.visible = true;
      _this.itemListInfo.title = "字典子项";
      _this.itemListInfo.info = info;
    },
    //子组件传值
    save() {
      _this.addedit.visible = false;
      _this.itemListInfo.visible = false;
      _this.GetList();
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>

